import { Container, Grid, Typography } from "@mui/material";
import React from "react";
import Seo from "../assets/components/seo";
import Navbar from "../assets/components/Navbar";
import Footer from "../assets/components/Footer";

const ChileChontal = () => {
  return (
    <Container
      maxWidth={false}
      sx={{
        padding: "0 !important",
        margin: "0 !important",
        backgroundColor: "#fff",
      }}
    >
      <Seo
        title="¿Qué es el Chile Chontal?"
        description=" En el año 2023, el estado de Tabasco fue invitado al Primer
              Festival Internacional del Chile en Nogada realizado en el World
              Trade Center en Ciudad de México. La invitación llegó por parte
              del organizador del festival, el chef internacional Jorge Orozco,
              reconocido ampliamente por ser el Embajador de la Comida
              Prehispánica"
      />

      <Navbar />
      <Grid
        container
        justifyContent="center"
        spacing={12}
        marginBottom={10}
        mt={12}
      >
        <img
          src="https://imagenturistica.tabasco.gob.mx/i.php?/upload/2024/05/13/20240513151055-289bdc7a-xx.jpg"
          alt="chile chontal"
          style={{
            width: "100%",
            height: "100%",
            maxHeight: 700,
            objectFit: "cover",
          }}
        />
        <Grid
          item
          position="absolute"
          zIndex={99}
          top={{ xs: 50, md: 250 }}
          margin="0 auto"
        >
          <Typography fontWeight="bold" fontSize={72} color="white">
            Chile Chontal
          </Typography>
          <Typography color="white" fontSize={32} textAlign="center">
            La raíz del sabor
          </Typography>
        </Grid>
      </Grid>
      <Grid container maxWidth={1240} margin="0 auto" my={12} paddingX={2}>
        <Grid item container>
          <Grid item xs={4}>
            <Typography
              textAlign="center"
              textTransform="uppercase"
              marginBottom={2}
            >
              Porciones
            </Typography>
            <Typography textAlign="center">16</Typography>
          </Grid>

          <Grid item xs={4}>
            <Typography
              textAlign="center"
              textTransform="uppercase"
              marginBottom={2}
            >
              Preparación
            </Typography>
            <Typography textAlign="center">2 horas</Typography>
          </Grid>

          <Grid item xs={4}>
            <Typography
              textAlign="center"
              textTransform="uppercase"
              marginBottom={2}
            >
              Tiempo de cocción
            </Typography>
            <Typography textAlign="center">1 hora</Typography>
          </Grid>
        </Grid>
        {/* Nacimiento del chile chontal */}
        <Grid item container mt={6} spacing={2}>
          <Grid item xs={12}>
            <Typography fontWeight="600" color="#AA2A35" fontSize={32}>
              Nace el Chile Chontal
            </Typography>
          </Grid>
          <Grid item xs={12} md={9}>
            <Typography>
              En el año 2023, el estado de Tabasco fue invitado al Primer
              Festival Internacional del Chile en Nogada realizado en el World
              Trade Center en Ciudad de México. La invitación llegó por parte
              del organizador del festival, el chef internacional Jorge Orozco,
              reconocido ampliamente por ser el Embajador de la Comida
              Prehispánica, con más de una década dedicada a la investigación y
              rescate de recetas ancestrales. Durante la participación del chef
              Orozco en el 11° Festival del Chocolate, se dio cuenta del interés
              de Tabasco por la innovación gastronómica relacionada con el cacao
              y el chocolate, por lo que puso en la mesa un gran reto: crear una
              versión del chile en nogada, pero tabasqueño, con ingredientes
              locales, cumpliendo así con la cuarta dimensión del festival, la
              gastronomía. Entonces vino el siguiente reto, qué chef realizaría
              este gran proyecto. Después de analizar y de buscar en el ambiente
              gastronómico de Tabasco y México, el secretario de turismo de la
              entidad, José Nieves encontró en el chef Alfonso Castañeda,
              reconocido por sus recetas de cocina tradicional, la persona
              idónea para este reto, un chile en nogada tabasqueño con
              ingredientes de la región como la carne y queso de búfala, dulces
              y frutos regionales, cacao y chocolate, naciendo así el “Chile
              Chontal”.
            </Typography>
          </Grid>
          <Grid item xs={12} md={3} textAlign="center">
            <img
              src="https://imagenturistica.tabasco.gob.mx/i.php?/upload/2024/05/21/20240521110140-e5a669cb-xx.jpg"
              style={{ width: "100%", maxWidth: 260 }}
              alt="chef"
            />
          </Grid>
        </Grid>
        {/* Fin del nacimiento del chile chontal */}

        {/* Ingredientes */}
        <Grid item container mt={6} spacing={2}>
          <Grid item xs={12}>
            <Typography fontWeight="600" color="#AA2A35" fontSize={32}>
              Ingredientes
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <ul>
              <li>3 kg. de chile poblano pelados</li>
              <li>2 kg. de Rib Eye de búfalo molido</li>
              <li>½ kg. de pierna de cerdo molida</li>
              <li>100 gr. de manteca de cerdo</li>
              <li>½ Cebolla blanca</li>
              <li>200 gr. de ajo</li>
              <li>½ kg. de jitomate troceado</li>
              <li>½ kg. de dulce de oreja de mico</li>
              <li>½ kg de dulce de papaya</li>
              <li>½ kg. de dulce de piña</li>
              <li>4 Plátanos machos fritos troceados</li>
              <li>100 gr. de almendras fileteadas</li>
              <li>100 gr. de alcaparras</li>
              <li>50 gr. de pimienta gorda</li>
              <li>Sal fina cantidad necesaria</li>
            </ul>
          </Grid>
          <Grid item xs={12} md={6}>
            <ul>
              <li>100 ml. de vino blanco semi seco</li>
              <li>300 gr. de arándanos</li>
              <li>50 gr. de canela</li>
              <li>200 gr. de azúcar</li>
              <li>½ kg. de nibs de cacao garapiñado</li>
              <li>1 kg. de provolone ahumado</li>
              <li>l kg. de queso mozzarella</li>
              <li>300 gr. de queso doble crema</li>
              <li>300 gr. de queso de cabra</li>
              <li>½ kg. de chocolate al 70%</li>
              <li>200 gr. de nueces de la India</li>
              <li>½ taza de miel de abeja</li>
              <li>2 pitayas</li>
              <li>2 carambolas</li>
              <li>Flores para decoración</li>
            </ul>
          </Grid>
        </Grid>
        {/* Fin de Ingredientes */}

        {/* Preparación */}
        <Grid item container mt={6} spacing={2}>
          <Grid item xs={12}>
            <Typography fontWeight="600" color="#AA2A35" fontSize={32}>
              Preparación
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography fontWeight="600">Paso 1 : Relleno</Typography>
            <Typography whiteSpace="pre-line" mb={2}>
              {
                "l. En una sartén grande, caliente la manteca y agregue la cebolla y el ajo finamente picados. Sofría hasta que estén dorados y fragantes. \n2. Agregue el Rib Eye de Búfalo, la pierna de cerdo y cocine hasta que esté bien cocido.\n3. Añada el jitomate troceado, sazone con una pizca de sal fina y parte de la pimienta gorda molida. Espera a que se cocine (espese) el jitomate."
              }
            </Typography>

            <Typography fontWeight="600">
              Paso 2: Incorporación de frutas, dulces, nueces y alcaparras
            </Typography>
            <Typography whiteSpace="pre-line" mb={2}>
              {
                "1. Agregue los dulces de oreja de mico, la papaya y el plátano, así como la piña picada a la mezcla de carne. Revuelva para combinar los sabores.\n2. Agregue las almendras fileteadas y continúe revolviendo para que los sabores se integren por completo. Añada las alcaparras.\n3. Vierta vino en la mezcla y remueva bien para realzar los sabores."
              }
            </Typography>

            <Typography fontWeight="600">
              Paso 3: Toque final de sabor
            </Typography>
            <Typography whiteSpace="pre-line" mb={2}>
              {
                "1. Incorpore la canela al relleno y mezcle para que todos los ingredientes se unan. \n2. Añada el resto de la pimienta gorda molida y agregue sal al gusto. Revuelva hasta obtener una mezcla uniforme y sabrosa."
              }{" "}
            </Typography>

            <Typography fontWeight="600">
              Paso 4: Rectifica la textura y sazón final
            </Typography>
            <Typography whiteSpace="pre-line" mb={2}>
              {
                "1. Incorpore suavemente los arándanos al relleno; estos añadirán un toque de frescura y un contraste de sabor único\n2. Agregue la canela y revuelva con cuidado para que todos los ingredientes se mezclen de manera armoniosa"
              }
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography fontWeight="600">
              Paso 5: Rellenar los chiles
            </Typography>
            <Typography whiteSpace="pre-line" mb={2}>
              {
                "1. Con mucho cuidado rellenar cada chile poblano.  Asegúrese de no romper los chiles durante el proceso."
              }
            </Typography>
            <Typography fontWeight="600">
              Piso 6: Salsa de quesos, nuez y chocolate
            </Typography>
            <Typography whiteSpace="pre-line" mb={2}>
              {
                "1. Licue los quesos, las nueces de la India y el chocolate, si es necesario puede agregar crema ácida\n2. Ajuste el dulzor con la miel."
              }
            </Typography>
            <Typography fontWeight="600">Paso 7: Servicio y montaje</Typography>
            <Typography whiteSpace="pre-line" mb={2}>
              {
                "1. Ponga cada chile relleno en un plato\n2. Bañe con la salsa de queso\n3. Espolvoree los nibs de cacao garapiñado\n4. Decore con un par de flores\n5. En el rapito del chile coloque una media luna de pitaya y una rebanada de carambola"
              }
            </Typography>
          </Grid>
        </Grid>
        {/* Fin de Preparación */}
      </Grid>
      <Footer />
    </Container>
  );
};

export default ChileChontal;
